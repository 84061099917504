
import { Prop, Component, Ref, Model, mixins, Watch, Emit } from 'nuxt-property-decorator';
import { TranslateResult } from '../../../../types/i18n';
import Hint from '../../../Hint.vue';
import { Collapsible } from '../../../../components/layout';
import InputTextMixin from './InputTextMixin';

@Component({ components: { Collapsible, Hint } })
export default class InputText extends mixins(InputTextMixin) {
  @Prop({ type: String, default: 'text' }) readonly type!: string;
  @Prop({ type: String }) readonly leadingText!: string | TranslateResult;
  @Prop({ type: String, default: null }) readonly leadingIcon!: string;
  @Prop(Boolean) readonly noTrim!: boolean;
  @Prop(Boolean) readonly useMask!: boolean;
  @Prop(Boolean) readonly hideRequiredMark!: boolean;

  @Ref() inputElement?: HTMLInputElement;

  @Model('input', { type: [String, Number], default: null }) readonly value!: string | number | null;

  @Emit('input')
  emitInput(newValue: string | number, trim = false) {
    const formattedValue = trim ? String(newValue).trim() : newValue;
    if (this.useMask && this.leadingText) {
      return formattedValue ? `${this.leadingText}${formattedValue}` : '';
    }
    return formattedValue;
  }

  get innerValue() {
    if (!this.value) return '';
    if (this.leadingText && this.useMask) {
      return String(this.value).replace(String(this.leadingText), '');
    }
    return this.value;
  }

  set innerValue(newValue: string | number) {
    this.emitInput(newValue);
  }

  get inputClassNames() {
    return {
      '-destructive': this.hasValidationError,
      '-with-leading-text': this.leadingText,
    };
  }

  get footerText() {
    if (this.hasValidationError) return this.firstValidationError;
    return this.help || '';
  }

  async mounted() {
    await this.$nextTick();
    if (this.autofocus) this.inputElement?.focus();
  }

  @Watch('innerValue.length')
  onValueChange(newValue: string | number, oldValue: string | number) {
    if (newValue !== oldValue && this.hasValidationError) this.triggerValidation();
  }

  public async onBlur() {
    this.$emit('blur');
    // emit trimmed value on blur only avoid trims on every keypress
    // wait for v-model to update before triggering validation
    if (!this.noTrim && this.innerValue) {
      this.emitInput(this.innerValue, true);
      await this.$nextTick();
    }
    // prevent required validation on blur
    if (this.innerValue) this.triggerValidation();
  }
}
