export default {
  credit_card: {
    number: 'Numéro de carte',
    name_on_card: 'Nom sur la carte',
  },
  billing_info: {
    title: 'Adresse de facturation',
    fullname: 'Nom / Compagnie',
    address_line_1: 'Adresse',
    city: 'Ville',
    postal_code: 'Code postal ou ZIP',
  },
};
