export default {
  file: {
    click_upload: 'Click to upload',
    drag_drop: 'drag and drop',
    upload_failed: 'Upload failed, please try a different file',
    upload_failed_mobile: 'Upload failed',
    view_file: 'View {fileName}',
    delete_file: 'Delete {fileName}',
    invalid_file: 'Invalid file',
    invalid_file_type: 'Invalid file type {extension}',
    invalid_file_size: 'File size must be less than {maxSize}',
    all_file_types: 'All files types accepted.',
    accepted_file_types: 'Accepted formats: {fileTypes}.',
    recommended_dimensions: 'Recommended dimensions: {dimensions}.',
    max_dimensions: 'Max dimensions: {dimensions}.',
    max_size: 'Max file size: {size}.',
  },
  date: {
    calendar: {
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    select_date: 'Select date',
    select_time: 'Select time',
    select_datetime: 'Select date & time',
  },
  select: {
    placeholder: 'Select an option',
    country_label: 'Country',
    state_label: 'Province / State',
  },
};
